import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { getElementChildren } from '../../utility/Dom';

export default class TeaserCarousel {
    constructor( carousel ) {
        this.breakPoints = {
            xl: window.matchMedia('(min-width:1200px)'),
            lg: window.matchMedia('(min-width:992px)'),
            md: window.matchMedia('(min-width:768px)'),
            sm: window.matchMedia('(min-width:500px)'),
            smMax: window.matchMedia('(max-width:499px)')
        };
        this.carousel = carousel;
        this.items = getElementChildren(carousel, 'teaser-carousel-item');
        this.numberOfItems = this.items.length;

        this.init();
        this.addEventListeners();
    }

    init() {
        const sliderInstance = new Swiper(this.carousel, {
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                },
                500: {
                    centeredSlides: false,
                    slidesPerView: 2,
                },
                768: {
                    centeredSlides: false,
                    slidesPerView: 3,
                },
                992: {
                    centeredSlides: false,
                    slidesPerView: 4
                }
            },
            centeredSlides: true,
            modules: [ Pagination ],
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            slidesPerView: 1,
            spaceBetween: 30
        });
        this.markSliderAsEnabled(this.carousel);

        // Bugfix
        // @see https://github.com/nolimits4web/swiper/issues/2915
        sliderInstance.on('slideChange', () => {
            sliderInstance.pagination.render();
            sliderInstance.pagination.update();
        });

        if (this.breakPoints.xl.matches === true) {
            this.destroySliderInstance(sliderInstance);
            this.markSliderAsDisabled(this.carousel);
            return;
        }

        if (this.breakPoints.lg.matches === true && this.numberOfItems < 4) {
            this.destroySliderInstance(sliderInstance);
            this.markSliderAsDisabled(this.carousel);
            return;
        }

        if (this.breakPoints.md.matches === true && this.numberOfItems < 3) {
            this.destroySliderInstance(sliderInstance);
            this.markSliderAsDisabled(this.carousel);
            return;
        }

        if (this.breakPoints.sm.matches === true && this.numberOfItems < 2) {
            this.destroySliderInstance(sliderInstance);
            this.markSliderAsDisabled(this.carousel);
            return;
        }

        if (this.breakPoints.smMax.matches === true && this.numberOfItems < 2) {
            this.destroySliderInstance(sliderInstance);
            this.markSliderAsDisabled(this.carousel);
        }
    }

    addEventListeners() {
        window.addEventListener('resize', () => this.init());
        window.addEventListener('orientationchange', () => this.init());
    }

    markSliderAsDisabled(slider) {
        slider.classList.add('is-disabled');
    }

    markSliderAsEnabled(slider) {
        slider.classList.remove('is-disabled');
    }

    destroySliderInstance(sliderInstance) {
        sliderInstance.destroy(true, true);
    }
}

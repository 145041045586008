import { getElement } from '../../utility/Dom';

const SEARCH_FORM_CLOSED_CLASS = 'js-search-form--closed';
const SEARCH_FORM_OPEN_CLASS = 'js-search-form--open';

export default class NavMain {
    constructor( navMain ) {
        this.body = document.querySelector( 'body' );
        this.navButton = getElement( 'nav-button' );
        this.navWrap = getElement( 'nav-wrap' );
        this._visible = false;

        this.setInitParams( navMain );
        this.setEventListener();
    }

    setInitParams( navMain ) {
        this.navLinks = navMain.querySelector( '.nav-main__list' );
        this.searchBar = navMain.querySelector( '.search-bar' );
        this.searchToggle = this.searchBar.querySelector( '.search-bar__toggle' );
        this.searchInput = this.searchBar.querySelector( '.search-bar__input' );
        this.inputWrapper = this.searchBar.querySelector( '.search-bar__input-wrapper' );

        // wait for fonts loading (final width)
        setTimeout( () => {
            this.setInitValues();
            this.adjustWidth();
        }, 100 );
    }

    setInitValues () {
        this.initNavLinksWidth = this.navLinks.offsetWidth;
        this.initSearchBarWidth = this.searchBar.offsetWidth;

        this.navLinks.style.width = `${this.initNavLinksWidth}px`;
        this.searchBar.style.width = `${this.initSearchBarWidth}px`;
    }

    adjustWidth () {
        setTimeout( () => {
            this.searchBar.style.width = '';
            this.navLinks.style.width = '';
        }, 130 );
    }

    setEventListener () {
        this.searchToggle.addEventListener( 'click', ( event ) => this.handleBar( event ) );

        this.navButton && this.navButton.addEventListener( 'click', () => this.toggle());
    }

    toggle () {
        // reset scrollPos on close/set on open
        // scrollPos is lost because of body-utility-class
        const toggleIcon = this.navButton.querySelector( 'use' );

        if ( this.isVisible ) {
            this.body.scrollTop = this.bodyScrollPosition;
            this.navButton.setAttribute( 'aria-expanded', 'false' );
            toggleIcon.setAttribute( 'xlink:href', '#menu' );
        }
        else {
            this.bodyScrollPosition = this.body.scrollTop;
            this.navButton.setAttribute( 'aria-expanded', 'true' );
            toggleIcon.setAttribute( 'xlink:href', '#cross' );
        }

        this.isVisible = !this.isVisible;

        // show / hide headerNav
        this.navWrap.classList.toggle( 'header__nav-wrapper--active' );

        // prevent any scrolling on headerNav
        this.body.classList.toggle( 'u-body-no-scroll' );

        this.navWrap.style.top = '';
    }

    handleBar( event ) {
        const element = event.currentTarget;
        const isOpen = element.classList.contains( SEARCH_FORM_OPEN_CLASS );

        if ( isOpen ) {
            this.closeSearchBar( element );
        }
        else {
            this.openSearchBar( element );
        }

        this.inputWrapper.classList.toggle( 'js-search-bar--open' );
    }

    closeSearchBar( element ) {
        this.navLinks.classList.remove( 'js-search-bar-transition' );
        this.navLinks.classList.add( 'js-search-bar-transition--smooth' );
        this.inputWrapper.classList.add( 'js-search-bar-transition' );
        this.searchBar.style.width = `${this.initSearchBarWidth}px`;
        this.navLinks.style.width = `${this.initNavLinksWidth}px`;
        element.classList.remove( SEARCH_FORM_OPEN_CLASS );
        element.classList.add( SEARCH_FORM_CLOSED_CLASS );

        setTimeout( () => {
            this.searchBar.style.width = '';
            this.navLinks.style.width = '';
        }, 650 );
    }

    openSearchBar( element ) {
        this.setInitValues();
        this.navLinks.classList.add( 'js-search-bar-transition' );
        this.navLinks.classList.remove( 'js-search-bar-transition--smooth' );
        this.inputWrapper.classList.remove( 'js-search-bar-transition' );
        this.searchInput.value = '';

        setTimeout( () => {
            this.searchBar.style.width = '100%';
            this.navLinks.style.width = '0px';
            element.classList.remove( SEARCH_FORM_CLOSED_CLASS );
            element.classList.add( SEARCH_FORM_OPEN_CLASS );
            this.searchInput.focus();
        }, 50 );
    }

    get isVisible() {
        return this._visible;
    }

    set isVisible( value ) {
        this._visible = value;
    }
}

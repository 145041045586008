export function getViewport () {
  let a = 'inner';
  let w = window;

  if (('innerWidth' in window) === false) {
    a = 'client';
    w = document.documentElement || document.body;
  }

  return {
    width: w[ a + 'Width' ],
    height: w[ a + 'Height' ]
  };
}

export function getViewportWidth () {
  return getViewport().width;
}

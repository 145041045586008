'use strict';

const SERVICE_LABEL_FLIPPEDCLASS = 'service-label__item--flipped';

export default class ServiceLabel {
    constructor( serviceLabel ) {
        const labels = serviceLabel.querySelectorAll( '.service-label__item' );

        for ( const label of labels ) {
            this.initLabel( label );
        }
    }

    initLabel( label ) {
        const card = label.querySelector( '.service-label__card' );
        const front = card.querySelector( '.service-label__front' );
        const back = card.querySelector( '.service-label__back' );

        label.setAttribute( 'tabindex', '0' );

        label.addEventListener( 'click', () => {
            this.flipLabel( label, card, front, back );
        } );

        label.addEventListener( 'keydown', ( e ) => {
            // on enter or space
            if ( e.keyCode === 13 || e.keyCode === 32 ) {
                e.stopPropagation();
                e.preventDefault();
                this.flipLabel( label, card, front, back );
            }
        } );

        window.addEventListener( 'load', () =>
            this.setCardHeight( card, front.clientHeight ) );

        window.addEventListener( 'resize', () =>
            this.setCardHeight( card, front.clientHeight ) );

        this.setCardHeight( card, front.clientHeight );
    }

    flipLabel( label, card, front, back ) {
        label.classList.toggle( SERVICE_LABEL_FLIPPEDCLASS );

        if ( label.classList.contains( SERVICE_LABEL_FLIPPEDCLASS ) ) {
            this.setCardHeight( card, back.clientHeight );
        }
        else {
            this.setCardHeight( card, front.clientHeight );
        }
    }

    setCardHeight( card, height) {
        card.style.height = height + 'px';
    }
}

/**
 * heavily inspired by:
 * https://github.com/GoogleChrome/sw-precache/blob/master/demo/app/js/service-worker-registration.js
 */
import process from 'process';

const BUILD = process.env.BUILD_NUMBER;

export default class Precache {
    constructor() {
        this.register();
    }

    register() {
        if ( !this.isServiceWorkerSupported() ) {
            return;
        }

        window.addEventListener( 'load', () => {
            // eslint-disable-next-line compat/compat
            navigator.serviceWorker
                .register( '/service-worker.js' )
                .then( ( reg ) => {

                    // updatefound is fired if service-worker.js changes.
                    reg.onupdatefound = () => {
                        this.onUpdateFound( reg );
                    };

                } )
                .catch( function( e ) {
                    console.error( `Error during service worker registration (Build: ${BUILD}):`, e );
                } );
        } );
    }

    onUpdateFound( reg ) {
        const installingWorker = reg.installing;

        installingWorker.onstatechange = () => {

            switch ( installingWorker.state ) {
                case 'installed': {
                    // eslint-disable-next-line compat/compat
                    if ( navigator.serviceWorker.controller ) {
                        console.log( `New or updated content is available. (Build: ${BUILD})` );
                    }
                    else {
                        console.log( `Static assets now available offline! (Build: ${BUILD})` );
                    }
                    break;
                }
                case 'redundant': {
                    console.error( `The installing service worker became redundant. (Build: ${BUILD})` );
                    break;
                }
            }
        };
    }

    isServiceWorkerSupported() {
        return 'serviceWorker' in navigator ? true : false;
    }
}

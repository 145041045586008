import smoothScroll from 'smoothscroll';

// todo check if smoothScroll is still needed because of css scroll-behavior: smooth;
export default class SmoothScrolling {
    constructor( smoothScrolling ) {
        this.initScrollAnimationEffect( smoothScrolling );
    }

    initScrollAnimationEffect( smoothScrolling ) {
        const target = smoothScrolling.getAttribute( 'href' );

        smoothScrolling.addEventListener( 'click', ( e ) => {
            e.preventDefault();

            smoothScroll( document.querySelector( target ) );
        });
    }
}

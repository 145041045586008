export function getElement (identifier) {
    return document.querySelector('[data-js=' + identifier + ']');
}

export function getElements (identifier) {
    return document.querySelectorAll('[data-js=' + identifier + ']');
}

export function getElementChild (element, identifier) {
    if (element === null) {
        return null;
    }

    return element.querySelector('[data-js=' + identifier + ']');
}

export function getElementChildren (element, identifier) {
    if (element === null) {
        return [];
    }

    return element.querySelectorAll('[data-js=' + identifier + ']');
}

export function getClosest (element, identifier) {
    if (element === null) {
        return null;
    }

    return element.closest('[data-js=' + identifier + ']');
}

export default function toggleJsClass() {
    const html = document.querySelector('html');

    if (html === null) {
        return;
    }

    html.classList.remove('no-js');
    html.classList.add('js');
}

'use strict';

import { throttle } from 'throttle-debounce';

export default class ScrollFadeIn {

    constructor( element ) {
        this.init( element );
    }

    init( element ) {
        window.addEventListener( 'resize', throttle( 10, () => this.setOpacity( element ) ) );
        window.addEventListener( 'scroll', throttle( 10, () => this.setOpacity( element ) ) );

        // init on first time
        this.setOpacity( element );
    }

    setOpacity( element ) {
        const elementYInWindow = element.getBoundingClientRect().top,
            windowHeight = window.innerHeight,
            yOffsetOpacityVisible = 1 / 2. * windowHeight,
            yOffsetOpacityHidden = 7 / 8. * windowHeight;

        if ( elementYInWindow < yOffsetOpacityVisible ) {
            element.style.opacity = 1;
        }
        else if ( elementYInWindow > yOffsetOpacityHidden ) {
            element.style.opacity = 0;
        }
        else {
            element.style.opacity =
                ( elementYInWindow - yOffsetOpacityHidden ) /
                ( yOffsetOpacityVisible - yOffsetOpacityHidden );
        }
    }
}

import { getElement, getElementChild, getElementChildren } from '../../utility/Dom';

const STATE_ACTIVE = 'is-active';
const SEARCH_INPUT_FIELD_MIN_CHARS = 3;
const SEARCH_AUTOCOMPLETE_API_ENDPOINT_PARAMETER = '?eID=keSearchPremiumAutoComplete&wordStartsWith=';

// todo refactor autocomplete stuff to separate class/ module
export default class HeaderSearchForm {
    constructor () {
        this.form = getElement('header-search-form');
        this.searchButton = getElement('search-button');
        this.searchField = getElement('header-search-input');
        this.autocompleteBox = getElement('header-search-autocomplete-box');
        this.suggestItemCount = -1;
    }

    init () {
        if (
            this.form === null ||
            this.searchButton === null ||
            this.searchField === null
        ) {
            return;
        }

        this.addEventListeners();
    }

    addEventListeners () {
        this.searchButton.addEventListener('click', () => this.toggle());
        this.searchField.addEventListener( 'input', (e) => this.fetchSuggestions(e) );
        document.addEventListener( 'keydown', (e) => this.handleKeyDown(e) );
    }

    toggle () {
        this.form.classList.toggle(STATE_ACTIVE);
        this.searchButton.classList.toggle(STATE_ACTIVE);
        this.searchField.focus();
    }

    isInputFieldFocused( inputField ) {
        return inputField === document.activeElement;
    }

    fetchSuggestions( event ) {
        const inputField = event.target;
        const inputFieldValue = inputField.value.trim();
        const currentCharCount = inputFieldValue.length;
        const searchApiEndpointUrl = window.location.origin;
        const autoCompleteItemTemplate = getElementChild(this.form, 'header-search-autocomplete-item-template' );

        if ( currentCharCount >= SEARCH_INPUT_FIELD_MIN_CHARS ) {
            let ajaxRequest = new XMLHttpRequest();

            ajaxRequest.onreadystatechange = () => {
                if ( ajaxRequest.readyState === XMLHttpRequest.DONE ) {
                    if ( ajaxRequest.status === 200 ) {
                        let suggestItems = [];
                        const serverResponse = ajaxRequest.responseText;

                        if ( serverResponse.trim() !== '' ) {
                            suggestItems = JSON.parse( ajaxRequest.responseText );
                        }

                        if ( suggestItems.length ) {
                            this.autocompleteBox.classList.add( STATE_ACTIVE );
                            this.autocompleteBox.innerHTML = '';

                            suggestItems.forEach((suggestItem) => {
                                let searchAutoCompleteItem = null;

                                if ( autoCompleteItemTemplate !== null ) {
                                    searchAutoCompleteItem = document.importNode(
                                        autoCompleteItemTemplate.content,
                                        true
                                    );
                                }

                                if ( searchAutoCompleteItem !== null ) {
                                    const searchAutoCompleteItemLink = getElementChild(searchAutoCompleteItem, 'header-search-autocomplete-link');

                                    if ( searchAutoCompleteItemLink !== null ) {
                                        searchAutoCompleteItemLink.innerHTML = suggestItem;

                                        searchAutoCompleteItemLink.addEventListener( 'click', () => {
                                            inputField.value = searchAutoCompleteItemLink.innerText;
                                            this.hideAndClearAutocompleteBox( this.autocompleteBox );
                                            this.form.submit();
                                        });
                                    }

                                    this.autocompleteBox.append( searchAutoCompleteItem );
                                }
                            });
                        }
                    }
                }
            };

            ajaxRequest.open(
                'GET',
                searchApiEndpointUrl +
                SEARCH_AUTOCOMPLETE_API_ENDPOINT_PARAMETER +
                encodeURI( inputFieldValue
                ), true);
            ajaxRequest.send();
        }

        if ( currentCharCount < SEARCH_INPUT_FIELD_MIN_CHARS ) {
            this.hideAndClearAutocompleteBox( this.autocompleteBox );
        }

        this.suggestItemCount = -1;
    }

    handleKeyDown( event ) {
        const element = event.target;

        if (
            element.matches( '[data-js=header-search-input]' ) === true &&
            this.isInputFieldFocused( element ) === true
        ) {
            const key = event.key;
            let isEnterKeyPressed = false;

            if ( this.autocompleteBox.classList.contains( STATE_ACTIVE ) === false ) {
                return;
            }

            const autocompleteItems = getElementChildren(this.autocompleteBox, 'header-search-autocomplete-link');
            const autocompleteItemCount = autocompleteItems.length;

            switch (key) {
                case 'ArrowDown':
                    event.preventDefault();

                    if (autocompleteItemCount > 0) {
                        this.suggestItemCount++;
                    }

                    if ( this.suggestItemCount >= autocompleteItemCount ) {
                        this.suggestItemCount = autocompleteItemCount - 1;
                    }
                    break;

                case 'ArrowUp':
                    event.preventDefault();

                    if (autocompleteItemCount > 0) {
                        this.suggestItemCount--;
                    }

                    if ( this.suggestItemCount < 0 ) {
                        this.suggestItemCount = -1;
                    }
                    break;

                case 'Enter':
                    isEnterKeyPressed = true;
                    break;
            }

            autocompleteItems.forEach((autoCompleteItem, i) => {
                autoCompleteItem.classList.remove( STATE_ACTIVE );

                if (i === this.suggestItemCount) {
                    autoCompleteItem.classList.add( STATE_ACTIVE );
                }
            });

            if ( isEnterKeyPressed ) {
                const activeSuggestItem = this.autocompleteBox.querySelector( '.' + STATE_ACTIVE );

                if ( activeSuggestItem ) {
                    event.preventDefault();
                    element.value = activeSuggestItem.innerText;
                    this.form.submit();
                }
            }
        }
    }

    hideAndClearAutocompleteBox( autocompleteBox ) {
        autocompleteBox.classList.remove( STATE_ACTIVE );
        autocompleteBox.innerHTML = '';
    }
}

export default class PrintContent {
    constructor(element) {
        this.printer = {
            container: element,
            trigger: element.querySelectorAll( '.js-print__trigger' ),
            content: element.querySelectorAll( '.js-print__content' )
        };
        this.addListeners();
    }

    addListeners() {
        for ( let i = 0, len = this.printer.trigger.length; i < len; i++ ) {
            const el = this.printer.trigger[ i ];
            el.addEventListener( 'click', evt => this.triggerClicked( evt ) );
        }
    }

    triggerClicked() {
        document.body.classList.add( 'hidden-in-print' );
        for ( let i = 0, len = this.printer.content.length; i < len; i++ ) {
            const el = this.printer.content[ i ];
            this.moveUp( el );
        }
        window.print();
    }

    moveUp(el) {
        const PAGE_HEADER = document.querySelector( 'header.page__header' );
        PAGE_HEADER.parentNode.insertBefore( el, document.querySelector( 'header.page__header' ).nextSibling );
    }

}

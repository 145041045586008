import ally from 'ally.js';

const ACCORDION_INITIALIZED = 'accordion--initialized';
const ACCORDION_HIDDEN_CLASS = 'accordion__body--hidden';
const ACCORDION_ICON = '.accordion__icon use';
const KEY_CODE = ally.map.keycode;

export default class Accordion {
    constructor( accordion ) {
        this.accordionLabels = accordion.querySelectorAll( '.accordion__label' );
        this.accordionBodies = accordion.querySelectorAll( '.accordion__body' );

        this.init( accordion );
        this.bind();
    }

    init( accordion ) {
        // set ARIA for labels
        for ( const label of this.accordionLabels ) {
            label.querySelector( ACCORDION_ICON ).setAttribute( 'xlink:href', '#plus' );
            label.setAttribute( 'role', 'tab' );
            label.setAttribute( 'tabindex', '-1' );
            label.setAttribute( 'aria-expanded', 'false' );
            label.setAttribute( 'aria-selected', 'false' );
            label.setAttribute( 'aria-controls', label.dataset.ariaControls );
        }

        // hide all bodies
        for ( const body of this.accordionBodies ) {
            body.classList.add( ACCORDION_HIDDEN_CLASS );
            body.setAttribute( 'aria-hidden', 'true' );
            body.setAttribute( 'role', 'tabpanel' );
            body.setAttribute( 'aria-labelledby', body.dataset.ariaLabelledby );
        }

        // make first label tabbable
        this.accordionLabels.item( 0 ).setAttribute( 'tabindex', '0' );

        // see css ( prevent open accordion bodies )
        accordion.classList.add( ACCORDION_INITIALIZED );
    }

    bind() {
        // bind events
        for ( const label of this.accordionLabels ) {

            label.addEventListener( 'click', ( e ) => {
                this.toggleBody( e.currentTarget );
            } );

            label.addEventListener( 'keydown', ( e ) => {
                this.detectKeys( e );
            } );
        }
    }

    toggleBody( label ) {
        const body = label.nextElementSibling,
            isHidden = body.classList.contains( ACCORDION_HIDDEN_CLASS );

        this.selectLabel( label );

        if ( isHidden ) {
            // open body
            body.classList.remove( ACCORDION_HIDDEN_CLASS );
            label.querySelector( ACCORDION_ICON ).setAttribute( 'xlink:href', '#minus' );

            // set ARIA for label and body
            label.setAttribute( 'aria-expanded', 'true' );
            body.setAttribute( 'aria-hidden', 'false' );
        }
        else {
            // close body
            body.classList.add( ACCORDION_HIDDEN_CLASS );
            label.querySelector( ACCORDION_ICON ).setAttribute( 'xlink:href', '#plus' );

            // set ARIA for label and body
            label.setAttribute( 'aria-expanded', 'false' );
            body.setAttribute( 'aria-hidden', 'true' );
        }
    }

    selectLabel( label ) {
        for ( const label of this.accordionLabels ) {
            label.setAttribute( 'tabindex', '-1' );
            label.setAttribute( 'aria-selected', 'false' );
        }

        label.setAttribute( 'tabindex', '0' );
        label.focus();
        label.setAttribute( 'aria-selected', 'true' );
    }

    selectFirstLabel( e ) {
        e.stopPropagation();
        e.preventDefault();
        this.selectLabel( this.accordionLabels.item( 0 ) );
    }

    selectLastLabel( e ) {
        e.stopPropagation();
        e.preventDefault();

        let index = this.accordionLabels.length - 1;
        this.selectLabel( this.accordionLabels.item( index ) );
    }

    selectNextLabel( e ) {
        e.stopPropagation();
        e.preventDefault();

        let index = Array.prototype.indexOf.call( this.accordionLabels, e.target );

        if ( index < this.accordionLabels.length - 1 ) {
            index += 1;
        }
        else {
            index = 0;
        }

        this.selectLabel( this.accordionLabels.item( index ) );
    }

    selectPreviousLabel( e ) {
        e.stopPropagation();
        e.preventDefault();

        let index = Array.prototype.indexOf.call( this.accordionLabels, e.target );

        if ( index > 0 ) {
            index -= 1;
        }
        else {
            index = this.accordionLabels.length - 1;
        }

        this.selectLabel( this.accordionLabels.item( index ) );
    }

    /* eslint-disable max-statements */
    detectKeys( e ) {
        switch ( e.keyCode ) {
            case KEY_CODE.enter: {
                e.stopPropagation();
                e.preventDefault();
                this.toggleBody( e.target );
                break;
            }
            case KEY_CODE.space: {
                e.stopPropagation();
                e.preventDefault();
                this.toggleBody( e.target );
                break;
            }
            case KEY_CODE.end: {
                this.selectLastLabel( e );
                break;
            }
            case KEY_CODE.home: {
                this.selectFirstLabel( e );
                break;
            }
            case KEY_CODE.left: {
                this.selectPreviousLabel( e );
                break;
            }
            case KEY_CODE.up: {
                this.selectPreviousLabel( e );
                break;
            }
            case KEY_CODE.right: {
                this.selectNextLabel( e );
                break;
            }
            case KEY_CODE.down: {
                this.selectNextLabel( e );
                break;
            }
        }
    }
    /* eslint-enable max-statements */

}

'use strict';

import raf from 'raf';

export default class Parallax {
    constructor( parallax ) {
        const background = parallax.querySelector( '.js-parallax__background' );
        this.initParallaxEffect( background );
    }

    initParallaxEffect( background ) {
        background.style.willChange = 'transform';

        window.addEventListener( 'scroll', () => {
            raf( function tick() {
                const top = (-1) * background.getBoundingClientRect().top;
                const parallax = Math.max( 0, ( top / 2 ) );
                background.style.transform = 'translateY(' + parallax + 'px)';
            } );
        } );
    }
}

import { throttle } from 'throttle-debounce';

const VISIBLE_CLASS = 'fab--visible';
const ACTIVE_CLASS = 'fab--active';

export default class Fab {
    constructor( fab ) {
        this.lastPageYScroll = 0;
        this.scrollListenerAdded = false;

        this.init( fab );
    }

    init( fab ) {
        const scrollEffect = this.addScrollEffect.bind( this, fab );

        window.addEventListener( 'resize', throttle( 5, () => {
            const scrollHeight = document.body.scrollHeight;
            const innerHeight = window.innerHeight;
            const isLongPage = scrollHeight > 2 * innerHeight;

            // last 300px before page end
            this.maxPageY = scrollHeight - innerHeight - 300;

            if ( !this.scrollListenerAdded && isLongPage ) {
                window.addEventListener( 'scroll', scrollEffect );
                fab.classList.add( ACTIVE_CLASS );
                this.scrollListenerAdded = true;
            }
            else {
                window.removeEventListener( 'scroll', scrollEffect );
                fab.classList.remove( VISIBLE_CLASS, ACTIVE_CLASS );
                this.scrollListenerAdded = false;
            }
        } ) );

        window.dispatchEvent( new CustomEvent( 'resize' ) );
    }

    addScrollEffect( fab ) {
        const minimalScrollDepth = 300;
        const pageYScroll = document.documentElement.scrollTop || document.body.scrollTop || window.scrollY;

        if ( (pageYScroll < minimalScrollDepth || pageYScroll > this.lastPageYScroll) &&
            pageYScroll < this.maxPageY ) {
            fab.classList.remove( VISIBLE_CLASS );
        }
        else {
            fab.classList.add( VISIBLE_CLASS );
        }

        this.lastPageYScroll = pageYScroll;
    }
}

'use strict';

export default class Clickable {

    constructor( clickable ) {
        const triggers = clickable.querySelectorAll( '.js-clickable__trigger' );
        const target = clickable.querySelector( '.js-clickable__target' );

        this.initTriggers( triggers, target );
    }

    initTriggers( triggers, target ) {
        for ( const trigger of triggers ) {
            trigger.addEventListener( 'click', () => {
                target.click();
            } );
        }
    }

}


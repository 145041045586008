const HEADER_NAV_SUB_ACTIVE_CLASS = 'nav-sub__wrapper--active';
const HEADER_MINIMAL_CLASS = 'header--minimal';

export default class Header {
    constructor( header ) {
        this._bodyScrollPosition = 0;
        this._header = header;
        const body = document.querySelector( 'body' );
        const isMinimalHeader = header.classList.contains( HEADER_MINIMAL_CLASS );

        if ( isMinimalHeader === false ) {
            this.initNavMainSub( body, header );
            this.initNavLevelOneLinks( header );
            this.initNavLevelTwoLinks( header );
            this.initNavBackButton( header );
        }

        this.initResize( body );
    }

    isSmallDevice() {
        return window.matchMedia('(min-width: 50rem)');
    }

    initNavMainSub( body, header ) {
        const navElements = header.querySelectorAll( '.nav-main__link--submenu-handling, .nav-sub__panel' );
        const navMainLinks = header.querySelectorAll( '.nav-main__link--submenu-handling' );
        const navSubMenus = [ ...header.querySelectorAll( '.nav-sub__wrapper' ) ];
        const navSubPanels = header.querySelectorAll( '.nav-sub__panel' );
        const that = this;

        function bodyClickTouchendHandler( e ) {
            let hasUserClickedOutsideNav = false;

            for ( let element of navElements ) {
                if ( element.contains( e.target ) === true ) {
                    hasUserClickedOutsideNav = true;
                }
            }

            if ( hasUserClickedOutsideNav === false ) {
                that.setMainLink( navMainLinks );
                that.setActivePanel( navSubPanels );

                navSubMenus.forEach((navSubMenu) => {
                    that.hideNavSub( navSubMenu );
                });
            }
        }

        body.addEventListener( 'click', bodyClickTouchendHandler.bind( this ), false );
        body.addEventListener( 'touchend', bodyClickTouchendHandler.bind( this ), false );
        body.addEventListener( 'focusin', bodyClickTouchendHandler.bind( this ), false );
    }

    initNavLevelOneLinks( header ) {
        const navMainLinks = [ ...header.querySelectorAll( '[data-js="nav-link-1"]' ) ];
        const navSubPanels = [ ...header.querySelectorAll( '.nav-sub__panel' ) ];

        navMainLinks.forEach((navLink) => {
            const allLevelOneLinks = [ ...header.querySelectorAll( '[data-js="nav-link-1"]' ) ];
            const subMenu = navLink.nextElementSibling;
            const panelId = '#' + navLink.getAttribute( 'aria-controls' );
            let panel = null;

            if (panelId !== '#') {
                panel = header.querySelector( panelId );
            }

            navLink.addEventListener( 'focus', () => {
                allLevelOneLinks.forEach((levelOneLink) => {
                    levelOneLink.classList.remove( 'active' );
                });

                this.setActivePanel( navSubPanels, panel );
                this.showNavSub( subMenu );
            }, false );

            navLink.addEventListener( 'click', ( e ) => {
                e.preventDefault();

                allLevelOneLinks.forEach((levelOneLink) => {
                    levelOneLink.classList.remove( 'active' );
                });

                if ( navLink.getAttribute( 'aria-selected' ) === 'false' ) {
                    this.setMainLink( navMainLinks, e.currentTarget );
                    this.showNavSub( subMenu );
                }
                else {
                    this.setMainLink( navMainLinks );
                    this.setActivePanel( navSubPanels );
                    this.hideNavSub( subMenu );
                }
            }, false );
        });
    }

    initNavLevelTwoLinks( header) {
        const navLevelTwoLinks = [ ...header.querySelectorAll( '[data-js="nav-link-2"]' ) ];

        navLevelTwoLinks.forEach((navLevelTwoLink) => {
            const panel = navLevelTwoLink.closest('[data-js="nav-sub-panel"]');
            const allLevelOneLinks = [ ...header.querySelectorAll( '[data-js="nav-link-1"]' ) ];
            const relatedLevelOneLink = header.querySelector( '[aria-controls="' + panel.getAttribute('id') + '"]' );

            navLevelTwoLink.addEventListener('blur', () => {
                allLevelOneLinks.forEach((levelOneLink) => {
                    levelOneLink.classList.remove( 'active' );
                });
                relatedLevelOneLink.classList.add( 'active' );
            });

            navLevelTwoLink.addEventListener('focus', () => {
                allLevelOneLinks.forEach((levelOneLink) => {
                    levelOneLink.classList.remove( 'active' );
                });
                relatedLevelOneLink.classList.add( 'active' );
            });
        });
    }

    initNavBackButton( header ) {
        const navBackButtons = [ ...header.querySelectorAll( '.nav-sub__back' ) ];
        const navMainLinks = [ ...header.querySelectorAll( '.nav-main__link--submenu-handling' ) ];
        const navSubMenus = [ ...header.querySelectorAll( '.nav-sub__wrapper' ) ];
        const navSubPanels = [ ...header.querySelectorAll( '.nav-sub__panel' ) ];

        navBackButtons.forEach((navBackButton) => {
            navBackButton.addEventListener( 'click', () => {
                this.setMainLink( navMainLinks );

                navSubMenus.forEach((navSubMenu) => {
                    this.hideNavSub( navSubMenu );
                });

                this.setActivePanel( navSubPanels );
            });
        });
    }

    initResize( body ) {
        window.addEventListener( 'resize', () => {
            body.classList.remove( 'u-body-no-scroll' );
        } );
    }

    setActivePanel( panels, activePanel ) {
        for ( let panel of panels ) {
            if ( panel === activePanel ) {
                panel.setAttribute( 'aria-hidden', 'false' );
            }
            else {
                panel.setAttribute( 'aria-hidden', 'true' );
            }
        }
    }

    setMainLink( links, activeLink ) {
        for ( let link of links ) {
            if ( link === activeLink ) {
                link.setAttribute( 'aria-selected', 'true' );
                link.focus();
            }
            else {
                link.setAttribute( 'aria-selected', 'false' );
                link.blur();
            }
        }
    }

    hideNavSub( subMenu ) {
        subMenu && subMenu.classList.remove( HEADER_NAV_SUB_ACTIVE_CLASS );
    }

    showNavSub( subMenu ) {
        subMenu && subMenu.classList.add( HEADER_NAV_SUB_ACTIVE_CLASS );
    }

    get bodyScrollPosition() {
        return this._bodyScrollPosition;
    }

    set bodyScrollPosition( value ) {
        this._bodyScrollPosition = value;
    }
}

import Chart from 'chart.js';

const colorSet = [];
colorSet[ 'default' ] = [
    '#f1e000',
    '#ff992e',
    '#d1eaf2',
    '#569f9f',
    '#b8e2e2',
    '#9ca3af',
    '#004d4d',
    '#092526',
    '#1b6b6b',
    '#fcdebd',
    '#f6ee73'
];

const DIAGRAM_DEFAULTS = {
    type: 'doughnut',
    data: {
        datasets: [  ]
    },
    options: {
        title: {
            display: false,
            text: '',
            fontSize: 24,
            fontStyle: 'normal',
            fontFamily: "'Hanken Grotesk', Tahoma, sans-serif",
            fontColor: '#111111',
            padding: 10,
            position: 'top'
        },
        legend: {
          display: false,
          reverse: false,
          position: 'bottom',
          fullWidth: false,
          labels: {
            boxWidth: 30,
            usePointStyle: false
          }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        legendCallback: (chart) => {
            const text = [];
            text.push('<ul class="diagram__legend-list">');

            if (chart.data.datasets.length > 1) {
                chart.data.datasets.forEach((dataset) => {
                    text.push('<li class="diagram__legend-list-item">' +
                        '<span class="diagram__legend-mark" style="background-color:' +
                        dataset.backgroundColor + '"></span>');
                    text.push('<span class="diagram__legend-text" >');
                    text.push(dataset.label);
                    text.push('</span></li>');
                });
            }
            else {
                chart.data.labels.forEach((label, i) => {
                    text.push('<li class="diagram__legend-list-item">' +
                        '<span class="diagram__legend-mark" style="background-color:' +
                        chart.data.datasets[ 0 ].backgroundColor[ i ] +'"></span>');
                    text.push('<span class="diagram__legend-text" >');
                    text.push( label );
                    text.push('</span></li>');
                });
            }

            text.push('</ul>');

            return text.join('');
        }
    }
};

export default class Diagram {
    constructor( diagram ) {
        this.canvasEl = diagram.querySelector( 'canvas' );
        this.diagramId = this.canvasEl.id;
        const ctx = this.canvasEl;
        this.chartConfig = window[ 'data_' + this.diagramId ];

        if ( this.chartConfig.diagram.data.datasets.length > 1 ) {
            this.chartConfig.diagram.data.datasets.forEach((dataset, i) => {
                dataset.backgroundColor = colorSet[ this.chartConfig.colorset ][ i ];
            });
        }
        else {
            this.chartConfig.diagram.data.datasets[ 0 ].backgroundColor = colorSet[ this.chartConfig.colorset ];
        }

        const chartConfig = { ...DIAGRAM_DEFAULTS, ...this.chartConfig.diagram };
        chartConfig.options.title.text = this.chartConfig.headline.text;

        const customDiagram = new Chart( ctx, chartConfig );
        const legendEl = document.getElementById( this.diagramId + '__legend' );
        legendEl.innerHTML = customDiagram.generateLegend(customDiagram);
    }
}

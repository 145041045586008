export default class BridgePage {
    constructor() {
        this.lists = [ ...document.querySelectorAll('[data-js="bridge-page-list"]') ];
    }

    init() {
        if (this.lists.length === 0) {
            return;
        }

        this.registerEventListeners();
    }

    registerEventListeners() {
        window.addEventListener('load', () => {
            this.lists.forEach((bridgePageList) => {
                if (this.isSearchQueryParameterPresent() === true) {
                    bridgePageList.classList.add('u-hidden');
                }
            });
        });
    }

    isSearchQueryParameterPresent() {
        return /[?&]tx_kesearch_pi1%5Bsword%5D=/.test(window.location.search) ||
            /[?&]tx_kesearch_pi1[sword]=/.test(window.location.search);
    }
}

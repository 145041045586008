import { getClosest, getElements } from '../../utility/Dom';
import { getViewportWidth } from '../../utility/Viewport';

const TABLE_MANIPULATED_SELECTOR = 'data-manipulated';

export default class Table {
    constructor () {
        this.tables = getElements('table');
        this.tableReferences = [];
        this.breakpoint = 768;
    }

    init () {
        this.tables.forEach((table) => {
            this.unwrap(table);
            this.tableReferences = [ ...this.tableReferences, table.innerHTML ];
        });

        this.update();
        this.addEventListeners();
    }

    update () {
        if (getViewportWidth() >= this.breakpoint) {
            const manipulatedTables = document.querySelectorAll('[' + TABLE_MANIPULATED_SELECTOR + ']');

            manipulatedTables.forEach((manipulatedTable, index) => {
                manipulatedTable.innerHTML = this.tableReferences[ index ];
                manipulatedTable.removeAttribute(TABLE_MANIPULATED_SELECTOR);
            });
        }
        else {
            this.tables.forEach((table) => {
                if (this.isHeaderPositionLeft(table) === true) {
                    const tableRows = table.querySelectorAll('tr');
                    const tableRowCount = tableRows.length;
                    let newTableCells = [];

                    for (let i = 0; i < tableRowCount; i++) {
                        newTableCells[ i ] = [];
                    }

                    tableRows.forEach((tableRow) => {
                        const tableHead = tableRow.querySelector('th');
                        const tableCells = tableRow.querySelectorAll('td');

                        tableCells.forEach((tableCell, tableCellIndex) => {
                            tableCell.setAttribute('data-hl', tableHead.innerText);

                            newTableCells[ tableCellIndex ] = [ ...newTableCells[ tableCellIndex ], tableCell ];
                        });
                    });

                    tableRows.forEach((tableRow, tableRowIndex) => {
                        tableRow.innerHTML = '';

                        newTableCells[ tableRowIndex ].forEach((newTableCell) => {
                            tableRow.appendChild(newTableCell);
                        });
                    });
                }

                if (this.isHeaderPositionTop(table) === true) {
                    const tableHeadlines = table.querySelectorAll('th');
                    const tableCells = table.querySelectorAll('td');
                    const tableHeadlineCount = tableHeadlines.length;
                    let tableCellCounter = 0;

                    tableHeadlines.forEach((tableHeadline) => {
                        tableHeadline.setAttribute('aria-hidden', 'true');
                    });

                    tableCells.forEach((tableCell) => {
                        tableCell.setAttribute('data-hl', tableHeadlines[ tableCellCounter ].innerText);
                        tableCellCounter++;

                        if (tableCellCounter >= tableHeadlineCount) {
                            tableCellCounter = 0;
                        }
                    });
                }

                table.setAttribute(TABLE_MANIPULATED_SELECTOR, 'true');
            });
        }
    }

    addEventListeners () {
        window.addEventListener('resize', () => this.update());
    }

    unwrap (table) {
        const tableWrap = getClosest(table, 'table-wrap');

        if (tableWrap !== null) {
            const tableWrapParent = tableWrap.parentNode;

            tableWrapParent.insertBefore(table, tableWrap);
            tableWrapParent.removeChild(tableWrap);
        }
    }

    isHeaderPositionLeft (table) {
        return table.querySelector('th + td') !== null;
    }

    isHeaderPositionTop (table) {
        return table.querySelector('th + th') !== null;
    }
}

import 'babel-polyfill';
import Accordion from './main/Accordion/Accordion.js';
import BridgePage from './main/BridgePage/BridgePage';
import Cinemagraph from './main/Cinemagraph/Cinemagraph.js';
import Clickable from './main/Clickable/Clickable.js';
import ContactBox from './main/ContactBox/ContactBox.js';
import Diagram from './main/Diagram/diagram.js';
import Fab from './main/Fab/Fab.js';
import Form from './main/Form/Form.js';
import Header from './main/Header/Header.js';
import ImageGallery from './main/ImageGallery/imagegallery.vuejs';
import LinkUrlParameterProcessor from './main/Link/LinkUrlParameterProcessor';
import Map from './main/Google/Map';
import NavMain from './main/NavMain/NavMain.js';
import Parallax from './main/Parallax/Parallax.js';
import PasswordPrompt from './main/PasswordPrompt/PasswordPrompt';
import Precache from './main/Precache/Precache.js';
import PrintContent from './main/PrintContent/PrintContent.js';
import ScrollFadeIn from './main/ScrollFadeIn/ScrollFadeIn.js';
import Search from './main/Search/Search.js';
import HeaderSearchForm from './main/Search/HeaderSearchForm';
import ServiceLabel from './main/ServiceLabel/ServiceLabel.js';
import SmoothScrolling from './main/SmoothScrolling/SmoothScrolling.js';
import StickyLink from './main/StickyLink/StickyLink.js';
import Table from './main/Table/Table';
import TeaserCarousel from './main/TeaserCarousel/TeaserCarousel.js';
import Vue from 'vue';
import toggleJsClass, { getElements } from './utility/Dom';

// precache
{
    new Precache();
}

// set js class
toggleJsClass();

// nav main
{
    const navMainComponents = document.querySelectorAll( '.nav-main' );
    for ( const navMain of navMainComponents ) {
        new NavMain( navMain );
    }
}

// print-content
{
    const printContentComponents = document.querySelectorAll( '.js-print' );

    for ( const item of printContentComponents ) {
        new PrintContent( item );
    }
}

// header
{
    const headerComponents = document.querySelectorAll( '.page__header .header:not(.header--landing)' );

    for ( const item of headerComponents ) {
        new Header( item );
    }
}

// cinemagraph
{
    const cinemagraphComponents = document.querySelectorAll( '.cinemagraph' );

    for ( const cinemagraph of cinemagraphComponents ) {
        new Cinemagraph( cinemagraph );
    }
}

// contactbox
{
    const contactBoxComponents = document.querySelectorAll( '.contact-box' );

    for ( const contactBox of contactBoxComponents ) {
        new ContactBox( contactBox );
    }
}

// accordion
{
    const accordionComponents = document.querySelectorAll( '.accordion' );

    for ( const accordion of accordionComponents ) {
        new Accordion( accordion );
    }
}

// clickable
{
    const clickableComponents = document.querySelectorAll( '.js-clickable' );

    for ( const clickable of clickableComponents ) {
        new Clickable( clickable );
    }
}

// form : member-form, information-form
{
    const formComponents = document.querySelectorAll( '.form' );

    for ( const item of formComponents ) {
        new Form( item );
    }
}

// teaser carousels
{
    const teaserCarousels = getElements( 'teaser-carousel' );

    teaserCarousels.forEach( ( teaserCarousel ) => {
        new TeaserCarousel( teaserCarousel );
    });
}

// service-label
{
    const serviceLabels = document.querySelectorAll( '.service-label' );

    for ( const item of serviceLabels ) {
        new ServiceLabel( item );
    }
}

// news
{
    const newsListItem = document.querySelectorAll( '.js-scroll-fade-in' );

    for ( const item of newsListItem ) {
        new ScrollFadeIn( item );
    }
}

// parallax
{
    const parallaxComponents = document.querySelectorAll( '.js-parallax' );

    for ( const parallax of parallaxComponents ) {
        new Parallax( parallax );
    }
}

// fab
{
    const floatingActionButtons = getElements( 'fab' );

    floatingActionButtons.forEach( ( fab ) => {
        new Fab( fab );
    });
}

// smooth-scrolling
{
    const smoothScrollingComponents = getElements( 'smooth-scroll' );

    smoothScrollingComponents.forEach( ( smoothScrollingComponent ) => {
        new SmoothScrolling( smoothScrollingComponent );
    });
}

if ( document.getElementById( 'image-gallery-vue' ) ) {
    new Vue( {
        render: h => h( ImageGallery )
    } ).$mount( '#image-gallery-vue' );
}

// diagram with chart.js
{
    const diagrams = getElements( 'diagram' );

    diagrams.forEach( ( diagram ) => {
        new Diagram( diagram );
    } );
}

// password prompt
{
    new PasswordPrompt();
}

// search
{
    new Search();
    const headerSearchForm = new HeaderSearchForm();
    headerSearchForm.init();
}

// sticky link
{
    new StickyLink();
}

// google maps
{
    const googleMap = new Map();
    googleMap.init();

    window.googleMapsCallback = () => {
        googleMap.generate();
    };
}

{
    const linkUrlParameterProcessor = new LinkUrlParameterProcessor();
    linkUrlParameterProcessor.init();
}

{
    const bridgePage = new BridgePage();
    bridgePage.init();
}

{
    const responsiveTable = new Table();
    responsiveTable.init();
}

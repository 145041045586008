const STICKY_LINK_SELECTOR = '[data-js="sticky-link"]';
const CLOSE_BUTTON_SELECTOR = '[data-js="sticky-close"]';
const TOGGLE_SELECTOR = '[data-js="sticky-toggle"]';

export default class StickyLink {

    constructor() {
        this.stickyLink = document.querySelector(STICKY_LINK_SELECTOR);
        this.closeButton = document.querySelector(CLOSE_BUTTON_SELECTOR);
        this.toggle = document.querySelector(TOGGLE_SELECTOR);

        this.init();
    }

    init() {
        if (this.stickyLink) {
            this.showStickyLink();
            this.closeStickyLinkModal();
            this.openStickyLinkModal();
        }
    }

    showStickyLink() {
        setTimeout(() => {
            this.stickyLink.classList.add('sticky-link--sticky');
        },  500);

        const closedStickyLinkBefore = sessionStorage.getItem('closed_sticky_link');

        if (!closedStickyLinkBefore) {
            this.stickyLink.classList.add('sticky-link--open');
        }
    }

    closeStickyLinkModal() {
        this.closeButton.addEventListener('click', () => {
            this.stickyLink.classList.toggle('sticky-link--open');
            sessionStorage.setItem('closed_sticky_link', 'true');
        });
    }

    openStickyLinkModal() {
        this.toggle.addEventListener('click', () => {
            this.stickyLink.classList.toggle('sticky-link--open');
        });
    }
}

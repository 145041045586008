const CONTENT_CONTAINER_SELECTOR = 'body';
const CONTENT_CONTAINER_TOGGLE_CLASS = 'u-hidden';
const PASSWORD = 'VBUstream2020';
const PASSWORD_PROMPT_PATH_NAMES = [
    '/livestream/'
];

export default class PasswordPrompt {

    constructor() {
        this.content = document.querySelector( CONTENT_CONTAINER_SELECTOR );
        this.storage = window.sessionStorage;
        this.registerEventListener();
    }

    registerEventListener() {
        window.addEventListener( 'load', () => this.show() );
    }

    show() {
        let userInput;
        const pathName = window.location.pathname.toLowerCase();

        if ( this.content &&
            !this.storage.getItem( pathName ) &&
            PASSWORD_PROMPT_PATH_NAMES.indexOf( pathName ) !== -1
        ) {
            this.content.classList.add( CONTENT_CONTAINER_TOGGLE_CLASS );

            setTimeout(() => {
                do {
                    userInput = prompt( 'Passwort erforderlich:' );

                    if ( PASSWORD === userInput ){
                        this.storage.setItem( pathName, userInput );
                        this.content.classList.remove( CONTENT_CONTAINER_TOGGLE_CLASS );
                    }
                } while ( PASSWORD !== userInput );
            }, 300);
        }
    }
}

const MAP = '[data-js="google-map"]';
const MAP_TRIGGER_BUTTON = '[data-js="google-map-trigger"]';
const STATE_ACTIVE = 'is-active';
const STATE_HIDDEN = 'is-hidden';

export default class Map {
    constructor() {
        this.isGoogleMapsScriptInjected = false;
        this.map = document.querySelector(MAP);
        this.triggerButton = document.querySelector(MAP_TRIGGER_BUTTON);
        this.openInfoWindow = null;
        this.bounds = null;
    }

    init() {
        if (this.map === null ||
            this.triggerButton === null
        ) {
            return;
        }

        this.registerEventListeners();
    }

    registerEventListeners() {
        this.triggerButton.addEventListener('click', () => {
            this.activate();
        });
    }

    activate() {
        this.triggerButton.classList.add(STATE_ACTIVE);
        const serviceText = document.querySelector('[data-js="google-service-text"]');

        if (serviceText !== null) {
            serviceText.classList.add(STATE_HIDDEN);
        }

        this.injectGoogleMapsScript({
            key: this.map.getAttribute('data-api-key'),
            callback: 'googleMapsCallback'
        });
    }

    generate() {
        this.bounds = new google.maps.LatLngBounds();
        this.map.classList.add(STATE_ACTIVE);
        const customMarkers = JSON.parse(this.map.getAttribute('data-items'));

        const map = new google.maps.Map(this.map, {
            center: {
                lat: 51.000,
                lng: 10.000
            },
            zoom: 6
        });

        this.generateCustomMarkers(map, customMarkers);
        map.fitBounds(this.bounds);
    }

    generateCustomMarkers(map, customMarkers) {
        customMarkers.forEach((customMarker) => {
            let infoWindowContent = '<h3>' + customMarker.title + '</h3>';

            customMarker = this.addLatitudeAndLongitudeFromAddress(customMarker);
            infoWindowContent = this.addMedicalDisciplineInfoContent(infoWindowContent, customMarker);
            infoWindowContent = this.addAddressInfoContent(infoWindowContent, customMarker);
            infoWindowContent = this.addOpeningTimesInfoContent(infoWindowContent, customMarker);
            infoWindowContent = this.addLinkInfoContent(infoWindowContent, customMarker);

            const infoWindow = new google.maps.InfoWindow({
                content: infoWindowContent,
            });

            if (customMarker.hasOwnProperty('lat') === false ||
                customMarker.hasOwnProperty('long') === false
            ) {
                return;
            }

            this.bounds.extend(new google.maps.LatLng(customMarker.lat, customMarker.long));

            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(
                    customMarker.lat,
                    customMarker.long
                ),
                map: map,
                title: customMarker.title
            });

            marker && marker.addListener('click', () => {
                if (this.openInfoWindow !== null) {
                    this.openInfoWindow.close();
                }

                this.openInfoWindow = infoWindow;

                infoWindow && infoWindow.open({
                    anchor: marker,
                    map,
                    shouldFocus: false,
                });
            });
        });
    }

    addLatitudeAndLongitudeFromAddress(customMarker) {
        if (customMarker.address === null ||
            typeof customMarker.address === 'undefined'
        ) {
            return customMarker;
        }

        if (customMarker.address.hasOwnProperty('latitude') === false ||
            customMarker.address.hasOwnProperty('longitude') === false
        ) {
            return customMarker;
        }

        if (customMarker.address.latitude === '' ||
            customMarker.address.longitude === ''
        ) {
            return customMarker;
        }

        customMarker.lat = customMarker.address.latitude;
        customMarker.long = customMarker.address.longitude;

        return customMarker;
    }

    addLinkInfoContent(infoWindowContent, customMarker) {
        if (customMarker.link === null ||
            typeof customMarker.link === 'undefined'
        ) {
            return infoWindowContent;
        }

        infoWindowContent += '<a target="_blank" href="' + customMarker.link + '">Mehr Informationen</a>';

        return infoWindowContent;
    }

    addMedicalDisciplineInfoContent(infoWindowContent, customMarker) {
        if (customMarker.medicalDisciplines === null ||
            typeof customMarker.medicalDisciplines === 'undefined'
        ) {
            return infoWindowContent;
        }

        const medicalDisciplines = customMarker.medicalDisciplines;

        infoWindowContent += '<p style="margin-bottom:0">Schwerpunkte:</p><ul>';

        medicalDisciplines.forEach((medicalDiscipline) => {
            infoWindowContent += '<li>' + medicalDiscipline + '</li>';
        });

        infoWindowContent += '</ul>';

        return infoWindowContent;
    }

    addOpeningTimesInfoContent(infoWindowContent, customMarker) {
        if (customMarker.openingTimes === null ||
            typeof customMarker.openingTimes === 'undefined'
        ) {
            return infoWindowContent;
        }

        const openingTimes = customMarker.openingTimes;

        infoWindowContent += '<p style="margin:15px 0 5px"><b>Öffnungszeiten:</b></p>';
        infoWindowContent += '<table style="border:0;margin-bottom:15px">';

        openingTimes.forEach((openingTime) => {
            if (openingTime.hasOwnProperty('dayName') === false ||
                openingTime.hasOwnProperty('time') === false
            ) {
                return;
            }

            if (openingTime.time.hasOwnProperty('begin') === false ||
                openingTime.time.hasOwnProperty('end') === false
            ) {
                return;
            }

            infoWindowContent += '<tr>';
            infoWindowContent += '<td style="border:0;padding:0">';
            infoWindowContent += openingTime.dayName + ': ';
            infoWindowContent += '</td>';
            infoWindowContent += '<td style="border:0;padding:0">';
            infoWindowContent += openingTime.time.begin + ' - ';
            infoWindowContent += openingTime.time.end;
            infoWindowContent += '</td>';
            infoWindowContent += '</tr>';
        });

        infoWindowContent += '</table>';

        return infoWindowContent;
    }

    addAddressInfoContent(infoWindowContent, customMarker) {
        if (customMarker.address === null ||
            typeof customMarker.address === 'undefined'
        ) {
            return infoWindowContent;
        }

        if (customMarker.address.hasOwnProperty('street') === false ||
            customMarker.address.hasOwnProperty('houseNumber') === false ||
            customMarker.address.hasOwnProperty('zip') === false ||
            customMarker.address.hasOwnProperty('city') === false
        ) {
            return infoWindowContent;
        }

        const address = customMarker.address;

        infoWindowContent += '<p style="margin-bottom:0">';
        infoWindowContent += address.street + ' ' + address.houseNumber + '<br>';
        infoWindowContent += address.zip + ' ' + address.city;
        infoWindowContent += '</p>';

        return infoWindowContent;
    }

    injectGoogleMapsScript(options = {}) {
        if (this.isGoogleMapsScriptInjected === true) {
            throw new Error('Google Maps Api is already loaded.');
        }

        const optionQueryString = Object.keys(options)
            .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(options[ k ])}`)
            .join('&');

        const url = `https://maps.googleapis.com/maps/api/js?${optionQueryString}`;
        const script = document.createElement('script');

        script.setAttribute('src', url);
        script.setAttribute('async', '');
        script.setAttribute('defer', '');

        document.head.appendChild(script);

        this.isGoogleMapsScriptInjected = true;
    }
}

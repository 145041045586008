'use strict';

const ICON_PLAY = '#play';
const ICON_PAUSE = '#pause';

export default class Cinemagraph {

    constructor( cinemagraph ) {
        this.addEventListeners( cinemagraph );
    }

    addEventListeners( cinemagraph ) {
        const VIDEO = cinemagraph.querySelector( '.cinemagraph__video' );
        const TOGGLE = cinemagraph.querySelector( '.cinemagraph__toggle' );
        const SVG = cinemagraph.querySelector( 'use' );

        TOGGLE.addEventListener( 'click', () => {
            if ( VIDEO.paused  ) {
                this.play( VIDEO );
                this.setIcon( SVG, ICON_PAUSE );
            }
            else {
                this.pause( VIDEO );
                this.setIcon( SVG, ICON_PLAY );
            }
        } );
    }

    play( video ) {
        video.play();
    }

    pause( video ) {
        video.pause();
    }

    setIcon( svg, icon ) {
        svg.setAttribute( 'xlink:href', icon );
    }

}


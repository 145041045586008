const curlyBracketsVariableRegex = /{.*}/gi;

export default class LinkUrlParameterProcessor {
    constructor() {
        this.links = [ ...document.querySelectorAll('a:not([href="#"]):not([href="javascript:void(0)"])') ];
        this.urlParameters = {};
    }

    init() {
        if (this.links.length === 0) {
            return;
        }

        this.setUrlParameters();
        this.registerEventListeners();
    }

    registerEventListeners() {
        window.addEventListener('load', () => {
            this.links.forEach((link) => {
                let linkTarget = link.href;
                let newParams = [];

                if (this.isVariableInsideLinkTarget(linkTarget) === false) {
                    return;
                }

                linkTarget = this.replaceVariablesInsideLinkTarget(linkTarget);

                if (this.isVariableInsideLinkTarget(linkTarget) === false) {
                    link.href = linkTarget;
                    return;
                }

                const urlParts = linkTarget.split('?');

                if (urlParts.length >= 2) {
                    const queryParameterPairs = urlParts[ 1 ].split(/[&;]/g);

                    queryParameterPairs.forEach((queryParamPair) => {
                        if (queryParamPair.match(curlyBracketsVariableRegex) === null) {
                            newParams.push(queryParamPair);
                        }
                    });

                    link.href = urlParts[ 0 ] + (newParams.length > 0 ? '?' + newParams.join('&') : '');
                }
            });
        });
    }

    setUrlParameters() {
        const queryString = window.location.search;
        this.urlParameters = this.getUrlParamsFromUrl(queryString);
    }

    isVariableInsideLinkTarget(linkTarget) {
        return linkTarget.match(curlyBracketsVariableRegex) !== null;
    }

    replaceVariablesInsideLinkTarget(linkTarget) {
        for (let key in this.urlParameters) {
            linkTarget = linkTarget.replaceAll(
                new RegExp('{' + key + '}', 'gi'),
                encodeURIComponent( this.urlParameters[ key ] )
            );
        }

        return linkTarget;
    }

    // replace with "new URLSearchParams(queryString)"
    // if project browser support will allow it
    getUrlParamsFromUrl(queryString) {
        const params = {};

        if (queryString === '') {
            return params;
        }

        const query = queryString.substring(1);
        const vars = query.split('&');

        for (let i = 0; i < vars.length; i++) {
            const pair = vars[ i ].split('=');
            params[ pair[ 0 ] ] = decodeURIComponent(pair[ 1 ]);
        }

        return params;
    };
}

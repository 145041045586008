import { getElements } from '../../utility/Dom';

const SEARCH_AUTOCOMPLETE_API_ENDPOINT = '/site-autocomplete?q=';
const SEARCH_AUTOCOMPLETE_BOX = '[data-js="search-autocomplete-box"]';
const SEARCH_AUTOCOMPLETE_ITEM_TEMPLATE = '[data-js="search-autocomplete-item-template"]';
const SEARCH_AUTOCOMPLETE_ITEM_LINK = '[data-js="search-autocomplete-link"]';
const SEARCH_INPUT_FIELD = '[data-js="search-input"]';
const SEARCH_INPUT_FIELD_MIN_CHARS = 3;
const SEARCH_FORM = '[data-js="search-form"]';
const STATE_ACTIVE = 'is-active';

export default class Search {
    constructor() {
        this.inputFields = getElements( 'search-input' );
        this.suggestItemCount = -1;
        this.addEventListeners();
    }

    addEventListeners() {
        this.inputFields.forEach((inputField) => {
            inputField.addEventListener( 'input', (e) => this.fetchSuggestions(e) );
        });

        document.addEventListener( 'click', (e) => {
            const clickTarget = e.target;

            if ( !clickTarget.matches( SEARCH_AUTOCOMPLETE_ITEM_LINK ) ) {
                this.hideAndClearAllAutoCompleteBoxes();
            }
        });
        document.addEventListener( 'keydown', (e) => this.handleKeyDown(e) );
    }

    isInputFieldFocused( inputField ) {
        return inputField === document.activeElement;
    }

    fetchSuggestions( event ) {
        const inputField = event.target;
        const inputFieldValue = inputField.value.trim();
        const currentCharCount = inputFieldValue.length;
        const searchForm = inputField.closest( SEARCH_FORM );
        const autoCompleteBox = searchForm.querySelector( SEARCH_AUTOCOMPLETE_BOX );
        const autoCompleteItemTemplate = searchForm.querySelector( SEARCH_AUTOCOMPLETE_ITEM_TEMPLATE );
        const searchApiEndpointUrl = window.location.origin;

        if ( currentCharCount >= SEARCH_INPUT_FIELD_MIN_CHARS ) {
            let ajaxRequest = new XMLHttpRequest();

            ajaxRequest.onreadystatechange = () => {
                if ( ajaxRequest.readyState === XMLHttpRequest.DONE ) {
                    if ( ajaxRequest.status === 200 ) {
                        let suggestItems = [];
                        const serverResponse = ajaxRequest.responseText;

                        if ( serverResponse.trim() !== '' ) {
                            suggestItems = JSON.parse( ajaxRequest.responseText );
                        }

                        if ( suggestItems.length ) {
                            autoCompleteBox.classList.add( STATE_ACTIVE );
                            autoCompleteBox.innerHTML = '';

                            suggestItems.forEach((suggestItem) => {
                                let searchAutoCompleteItem;

                                if ( autoCompleteItemTemplate ) {
                                    searchAutoCompleteItem = document.importNode(
                                        autoCompleteItemTemplate.content,
                                        true
                                    );
                                }

                                if ( searchAutoCompleteItem ) {
                                    const searchAutoCompleteItemLink = searchAutoCompleteItem.querySelector(
                                        SEARCH_AUTOCOMPLETE_ITEM_LINK
                                    );

                                    if ( searchAutoCompleteItemLink ) {
                                        searchAutoCompleteItemLink.innerHTML = suggestItem;

                                        searchAutoCompleteItemLink.addEventListener( 'click', () => {
                                            inputField.value = searchAutoCompleteItemLink.innerText;
                                            this.hideAndClearAutoCompleteBox( autoCompleteBox );
                                            searchForm.submit();
                                        });
                                    }

                                    autoCompleteBox.append( searchAutoCompleteItem );
                                }
                            });
                        }
                    }
                }
            };

            ajaxRequest.open(
                'GET',
                searchApiEndpointUrl +
                SEARCH_AUTOCOMPLETE_API_ENDPOINT +
                encodeURI( inputFieldValue ), true);
            ajaxRequest.send();
        }

        if ( currentCharCount < SEARCH_INPUT_FIELD_MIN_CHARS ) {
            this.hideAndClearAutoCompleteBox( autoCompleteBox );
        }

        this.suggestItemCount = -1;
    }

    handleKeyDown( event ) {
        const element = event.target;

        if ( element.matches( SEARCH_INPUT_FIELD ) &&
            this.isInputFieldFocused( element )
        ) {
            const key = event.key;
            const searchForm = element.closest( SEARCH_FORM );
            const autoCompleteBox = searchForm.querySelector( SEARCH_AUTOCOMPLETE_BOX );
            let isEnterKeyPressed = false;

            if ( !autoCompleteBox.classList.contains( STATE_ACTIVE ) ) {
                return;
            }

            const autoCompleteItems = autoCompleteBox.querySelectorAll( SEARCH_AUTOCOMPLETE_ITEM_LINK );
            const autoCompleteItemCount = autoCompleteItems.length;

            switch (key) {
                case 'ArrowDown':
                    event.preventDefault();

                    if (autoCompleteItemCount) {
                        this.suggestItemCount++;

                        if ( this.suggestItemCount >= autoCompleteItemCount ) {
                            this.suggestItemCount = autoCompleteItemCount - 1;
                        }
                    }
                    break;

                case 'ArrowUp':
                    event.preventDefault();

                    if (autoCompleteItemCount) {
                        this.suggestItemCount--;

                        if ( this.suggestItemCount < 0 ) {
                            this.suggestItemCount = -1;
                        }
                    }
                    break;

                case 'Enter':
                    isEnterKeyPressed = true;
                    break;
            }

            autoCompleteItems.forEach((autoCompleteItem, i) => {
                autoCompleteItem.classList.remove( STATE_ACTIVE );

                if (i === this.suggestItemCount) {
                    autoCompleteItem.classList.add( STATE_ACTIVE );
                }
            });

            if ( isEnterKeyPressed ) {
                const activeSuggestItem = autoCompleteBox.querySelector( '.' + STATE_ACTIVE );

                if ( activeSuggestItem ) {
                    event.preventDefault();
                    element.value = activeSuggestItem.innerText;
                    searchForm.submit();
                }
            }
        }
    }

    hideAndClearAutoCompleteBox( autoCompleteBox ) {
        autoCompleteBox.classList.remove( STATE_ACTIVE );
        autoCompleteBox.innerHTML = '';
    }

    hideAndClearAllAutoCompleteBoxes() {
        const autoCompleteBoxes = document.querySelectorAll( SEARCH_AUTOCOMPLETE_BOX );

        autoCompleteBoxes.forEach((autoCompleteBox) => {
            this.hideAndClearAutoCompleteBox( autoCompleteBox );
        });
    }
}

import { throttle } from 'throttle-debounce';

const CONTACT_BOX_STICKY_CLASS = 'contact-box--sticky-top';

export default class ContactBox {
    constructor( contactBox ) {
        this.setInitialOffset( contactBox );
        this.addEventHandlers( contactBox );
    }

    setInitialOffset( contactBox ) {
        if ( !contactBox.classList.contains( CONTACT_BOX_STICKY_CLASS ) ) {
            const offset = contactBox.getBoundingClientRect().top + window.pageYOffset;
            // override negative values set by safari
            this._initialOffset = offset > 0 ? offset : 0;
        }
    }

    addEventHandlers( contactBox ) {
        window.addEventListener( 'scroll', throttle( 10, () => {
            this.setInitialOffset( contactBox, CONTACT_BOX_STICKY_CLASS );
            this.reposition( contactBox, CONTACT_BOX_STICKY_CLASS );
        } ) );

        window.addEventListener( 'resize', throttle( 10, () => {
            this.setInitialOffset( contactBox, CONTACT_BOX_STICKY_CLASS );
            this.reposition( contactBox, CONTACT_BOX_STICKY_CLASS );
        } ) );

        window.addEventListener( 'load', () => {
            this.reposition( contactBox, CONTACT_BOX_STICKY_CLASS );
        } );
    }

    // set sticky on large devices, when scrolled out of view
    reposition( contactBox ) {
        const scrollTop = window.pageYOffset;

        if ( scrollTop > this.initialOffset ) {
            contactBox.classList.add( CONTACT_BOX_STICKY_CLASS );
        }
        else if ( scrollTop < this.initialOffset ) {
            contactBox.classList.remove( CONTACT_BOX_STICKY_CLASS );
        }
    }

    get initialOffset() {
        return this._initialOffset;
    }
}
